@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    /* Add standard and vendor-prefixed appearance properties */
    -webkit-appearance: none; /* Chrome, Safari */
    -moz-appearance: none;    /* Firefox */
    appearance: none;         /* Standard property for broader compatibility */

    @apply bg-white dark:bg-neutral-900 border-neutral-200 dark:border-neutral-700 
           rounded-full focus:ring-indigo-500 focus:border-indigo-500 block w-full 
           sm:text-sm text-neutral-900 dark:text-neutral-200;
  }

  .bg-custom-color {
    background-color: #078872;
  }

  .text-custom-color {
    color: #078872;
  }
}

.nc-PageContact {
  position: relative;
}

.nc-PageContact img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .nc-PageContact img {
    object-fit: contain;
  }
}

/* Apply aspect ratio to the image */
.image-container {
  aspect-ratio: 4 / 3; /* Replace with the correct aspect ratio */
  width: 100%;
}

.image-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nc-CardCategory3 {
  width: 100%; /* Full width */
  max-width: 300px; /* Default max width for smaller screens */
}

@media (min-width: 768px) {
  /* Adjust the min-width to match your breakpoint settings */
  .nc-CardCategory3 {
    max-width: 230px; /* Max width for larger screens */
  }
}

