.btn-search {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #10b981; /* Adjust as needed */
    color: white;
    border-radius: 9999px; /* Fully rounded */
    transition: background-color 0.3s;
  }
  
  .btn-search:hover {
    background-color: #059669; /* Adjust as needed */
  }
  