// FONT FACE --INTER
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../fonts/Inter/static/Inter-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
  font-display: swap;
}

// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
  font-display: swap;
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Poppins;
  --font-body: Poppins;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
